<template>
  <div id="app">
    <div class="outer_wrapper">
      <div class="left container">
        <div class="columns">
          <div class="column is-four-fifhts">
            <img alt="Pixelache logo" src="https://pixelache.ac/assets/pixelache/images/PA_logo.png" />
            <h1 class="title">All projects:</h1>
            <Project :key="'project_' + project.id" v-for="project in allProjects" :proj="project" @newJson="updateJsonInfo" />
          </div>
        </div>
      </div>
      <div class="right">
        <h2 class="subtitle">JSON response:</h2>
        <div style="json_url">http://api.pixelache.ac/v1{{ jsonUrl }}</div>
        <div class="columns">
          <div class="column">
            <json-view :data="rawJson" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Project from '@/components/Project'
  import { JSONView } from 'vue-json-component'

export default {
  name: 'PixelacheProjects',
  components: { Project,
  'json-view': JSONView  },
  data() {
    return {
      rawJson: null,
      allProjects: [],
      sortedProjects: {},
      jsonUrl: '/projects?page[size]=100&roots=true'
    }
  },
  methods : {
    updateJsonInfo(newJsonArray) {
      this.jsonUrl = newJsonArray[0]
      this.rawJson = newJsonArray[1]
    }
  },
  mounted () {
    // pass roots=true to the API to only return top-level projects
    axios.get('https://api.pixelache.ac/v1' + this.jsonUrl)
    .then((projectsResponse) => {
      // Let's display the raw json in a panel for reference
      this.rawJson = projectsResponse.data
      // Sort first by date of first activity
      this.allProjects = projectsResponse.data.data.sort((a,b) => { return this.$moment(a.attributes.first_activity) - this.$moment(b.attributes.first_activity) })
    })
  }
  
}
</script>

<style lang="scss">
@import 'assets/css/pixelache.scss';
</style>
