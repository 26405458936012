<template>
  <div class="project" v-if="proj.attributes" :style="generateCSS(proj.attributes)">
    <div class="project_image">
      <img v-if="proj.attributes.background_url" :src="proj.attributes.background_url" />
    </div>
    <div class="project_metadata">
      <span class="is-size-6" v-if="proj.attributes.evolvedfrom_id">Evolved from {{ proj.attributes.evolvedfrom_name }}<span class="is-size-7"> (id
        #{{ proj.attributes.evolvedfrom_id }})</span></span>
      <h2 class="subtitle" :style="'color: #' + proj.attributes.project_text_colour">{{ proj.attributes.name }} <span class="is-size-7">(id #{{ proj.id }})</span></h2>
      <p v-if="proj.attributes.first_activity && proj.attributes.last_activity">
        {{ $moment(proj.attributes.first_activity).format('YYYY') }} &mdash; {{ $moment(proj.attributes.last_activity).format('YYYY') }}</p>
      <p v-else>No activity</p>
    </div>
    <button v-if="proj.attributes.children_ids.length > 0" type="button" class="button child_project_button" @click="getChildren(proj.id)"> 
      {{ proj.attributes.children_ids.length }} child project(s)
    </button>
    <div class="child_target"  v-if="proj.attributes.children_ids.length > 0" :id="'target_' + proj.id">
      <Project :key="'project_' + childProject.id" v-for="childProject in childProjects" :proj="childProject"  @newJson="recursiveEmit" />
    </div>
    <button v-if="proj.attributes.evolvedto_id" type="button" class="child_project_button button" @click="getEvolved(proj.attributes.evolvedto_id)">
      Evolved into {{ proj.attributes.evolvedto_name }} <span v-if="proj.attributes.evolution_year ">in {{ proj.attributes.evolution_year }} </span> <span class="is-size-7"> (id #{{ proj.attributes.evolvedto_id }})</span>
    </button>
    <Project v-if="evolvedProject" :proj="evolvedProject" @newJson="recursiveEmit" />
  </div>
</template>

<script>
import Project from '@/components/Project'
import axios from 'axios'

export default {
  name: 'Project',
  components: { Project },
  props: {
    proj: Object,
  },
  data() {
    return {
      childProjects: [],
      evolvedProject: {},
      jsonUrl: null
    }
  },
  methods: {
    generateCSS(projectAttributes) {
      let style = '';
      if (projectAttributes.project_bg_colour) {
        style += 'background-color: #' + projectAttributes.project_bg_colour + ';'
      }
      if (projectAttributes.project_link_colour) {
        style += 'border: 2px solid #' + projectAttributes.project_bg_colour + ';'
      } else {
        style += 'border: 2px solid #AAA;'
      }
      if (projectAttributes.project_text_colour) {
        style += 'color: #' + projectAttributes.project_text_colour + ';'
      }
      return style
         // + '; border: 2px solid #' + proj.attributes.project_link_colour + '; color: #' + proj.attributes.project_text_colour + ';'
    },
    getChildren(parentId) {
      this.jsonUrl = '/projects?page[size]=100&from_parent=' + parentId
      axios.get('https://api.pixelache.ac/v1' + this.jsonUrl)
        .then((response) => {
          this.childProjects = response.data.data
          this.$emit('newJson', [this.jsonUrl, this.childProjects])
        })

    },
    getEvolved(id) {
      this.jsonUrl = '/projects/' + id
      axios.get('https://api.pixelache.ac/v1' + this.jsonUrl)
        .then((response) => {
          this.evolvedProject = response.data.data
          this.$emit('newJson', [this.jsonUrl, this.evolvedProject])
        })

    },
    recursiveEmit(newJsonArray) {
      this.$emit('newJson', [newJsonArray[0], newJsonArray[1]])
    }
  }
}
</script>

